import { computed, defineComponent } from "@vue/composition-api";

import ThumbsUp from '@/assets/thumbs-up.svg';
import ThumbsDown from '@/assets/thumbs-down.svg';
import ThumbsUpActive from '@/assets/thumbs-up-active.svg';
import ThumbsDownActive from '@/assets/thumbs-down-active.svg';

export default defineComponent({
    name: 'Thumbs',

    props: {
        isActive: {
            type: Boolean,
            default: true
        }
    },

    setup(props) {
        const source = computed(() => {
            return {
                thumbsUp: props.isActive ? ThumbsUpActive : ThumbsUp,
                thumbsDown: props.isActive ? ThumbsDownActive : ThumbsDown,
            }
        });
        
        return {
            source
        }
    }
});