import { defineComponent } from "@vue/composition-api";

import Star from './Star/index.vue';
import Thumbs from './Thumbs/index.vue';
import TwoEmojis from './TwoEmojis/index.vue';
import FiveEmojis from './FiveEmojis/index.vue';

const components = {
    stars: Star,
    thumbs: Thumbs,
    twoEmojis: TwoEmojis,
    fiveEmojis: FiveEmojis
}

export default defineComponent({
    name: 'Score',

    components: {
        Star,
        Thumbs
    },

    props: {
        type: {
            type: String,
            default: 'stars'
        }
    },

    setup() {
        return {
            components
        }   
    }
});