import { computed, defineComponent, reactive } from "@vue/composition-api";

import Angry from '@/assets/angry.svg';
import AngryActive from '@/assets/angry-active.svg';
import Love from '@/assets/love.svg';
import LoveActive from '@/assets/love-active.svg';

export default defineComponent({
    name: 'TwoEmojis',

    props: {
        isActive: {
            type: Boolean,
            default: true
        }
    },

    setup(props) {
        const source = computed(() => ({
            angry: props.isActive ? AngryActive : Angry,
            love: props.isActive ? LoveActive : Love
        }));

        return {
            source
        }
    }
});