import { computed, defineComponent } from "@vue/composition-api";

import Star from '@/assets/star.svg';
import StarActive from '@/assets/star-active.svg';

export default defineComponent({
    name: 'Star',

    props: {
        isActive: {
            type: Boolean,
            default: true
        }
    },

    setup(props) {
        const source = computed(() => {
            return props.isActive ? StarActive : Star;
        });

        return {
            source
        }
    }
});