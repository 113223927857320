import { computed, defineComponent, reactive } from "@vue/composition-api";

import Angry from '@/assets/angry.svg';
import AngryActive from '@/assets/angry-active.svg';
import Sad from '@/assets/sad.svg';
import SadActive from '@/assets/sad-active.svg';
import Happy from '@/assets/happy.svg';
import HappyActive from '@/assets/happy-active.svg';
import Smile from '@/assets/smile.svg';
import SmileActive from '@/assets/smile-active.svg';
import Love from '@/assets/love.svg';
import LoveActive from '@/assets/love-active.svg';

export default defineComponent({
    name: 'TwoEmojis',

    props: {
        isActive: {
            type: Boolean,
            default: true
        }
    },

    setup(props) {
        const source = computed(() => ({
            angry: props.isActive ? AngryActive : Angry,
            sad: props.isActive ? SadActive : Sad,
            happy: props.isActive ? HappyActive : Happy,
            smile: props.isActive ? SmileActive : Smile,
            love: props.isActive ? LoveActive : Love
        }));

        return {
            source
        }
    }
});