import { defineComponent, ref } from "@vue/composition-api";
import axios from 'axios';

import SurveyViewer from '@/components/SurveyViewer/index.vue';

interface Survey {
    question: string;
    followUpQuestion: string;
    scoreType: string;
    shouldCollectEmail: boolean;
}

export default defineComponent({
    name: 'QuickStart',

    components: {
        SurveyViewer
    },

    setup() {
        const forms = ref([
            {
                id: 1,
                title: 'Product Feedbacks',
                selected: true,
                survey: {
                    question: 'How good products make you feel?',
                    followUpQuestion: 'Give us any feedback would be appreciated',
                    scoreType: 'stars',
                    shouldCollectEmail: true
                }
            },
            {
                id: 2,
                title: 'Checkout issues',
                selected: false,
                survey: {
                    question: 'The checkout process still works well?',
                    followUpQuestion: 'Tell us about the problem that you want us to be solve',
                    scoreType: 'thumbs',
                    shouldCollectEmail: true
                }
            },
            {
                id: 3,
                title: 'Customer Feedbacks',
                selected: false,
                survey: {
                    question: 'What do you think about FeBa?',
                    followUpQuestion: 'Give us any feedback would be appreciated',
                    scoreType: 'fiveEmojis',
                    shouldCollectEmail: true
                }
            }
        ]);

        const selectForm = (formId: number) => {
            forms.value = forms.value.map(form => {
                form.selected = form.id == formId;
                return form;
            });
        }

        const handleSubmit = async (survey: Survey) => {
            await axios({
                url: 'http://localhost:3000/surveys',
                method: 'PUT',
                data: survey
            });
            alert('done');
        }

        return {
            forms,
            selectForm,
            handleSubmit
        }
    }
});