import { defineComponent } from "@vue/composition-api";

import Score from '../Score/index.vue';

export default defineComponent({
    name: 'SurveyViewer',

    components: {
        Score
    },

    props: {
        survey: {
            type: Object,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },

    setup() {
        
    }
});